.container {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.glass {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  background: rgba(161, 140, 94, 0.37);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);
  border: 1px solid rgba(161, 140, 94, 0.3);
  border-radius: 15px;
  padding: 1vh;
}

.fieldLabel {
  text-align: center;
  /* font-size: 4vw; */
  margin-block: 2vh;
  /* font-family: creveland-midleton; */
  color: var(--color-primary-4);
  font-weight: bold;
  /* letter-spacing: 2px; */
}

.fieldText {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 4vw;
  font-weight: 600;
}

.fieldNameText {
  text-align: center;
  text-transform: uppercase;
  font-size: 2.5vw;
}

.submitButton,
.secondaryButton {
  border-radius: 25px;
  margin-top: 2vh;
  letter-spacing: 2px;
  color: var(--color-secondary-2-1);
  background-color: var(--color-primary-3);
  font-size: 3vw;
}

.submitButton {
  color: var(--color-secondary-2-1);
  background-color: var(--color-primary-3);
}

.secondaryButton {
  color: var(--color-primary-3);
  background-color: var(--color-secondary-2-1);
}

@media (min-width: 768px) {
  .fieldText {
    font-size: 3vw;
  }
  .fieldNameText {
    font-size: 2.25vw;
  }
  .submitButton,
  .secondaryButton {
    font-size: 2.5vw;
  }
}
@media (min-width: 900px) {
  .fieldText {
    font-size: 2vw;
  }
  .fieldNameText {
    font-size: 1.5vw;
  }
  .submitButton,
  .secondaryButton {
    font-size: 2vw;
  }
}
@media (min-width: 1200px) {
  .fieldText {
    font-size: 2vw;
  }
  .fieldNameText {
    font-size: 1.5vw;
  }
  .submitButton,
  .secondaryButton {
    font-size: 1.5vw;
  }
}
@media (min-width: 1536px) {
  .fieldText {
    font-size: 1.5vw;
  }
  .fieldNameText {
    font-size: 1vw;
  }
  .submitButton,
  .secondaryButton {
    font-size: 1vw;
  }
}